<template>
  <div class="main-template-div lab22-mv-padding">
    <!--<ModuleHeader iconClass="fa-solid fa-user" :title="completeName" :subtitle="data?.username">-->
      <ModuleHeader  :title="completeName" :subtitle="data?.username" :showBackButton="true">
      <div class="d-flex">
        <div class="btn-group">
          <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-gear"></i></button>
          <ul class="dropdown-menu dropdown-menu-end">
            <!--<li><a class="dropdown-item" @click.prevent="changePassword()"><i class="fa-solid fa-hashtag"></i>Change password</a></li>-->
            <li><a class="dropdown-item" @click.prevent="sendActivationEmail()"><i class="fa-solid fa-envelope"></i> Send activation email</a></li>
            <li><a class="dropdown-item" @click.prevent="sendResetPasswordEmail()"><i class="fa-solid fa-envelope"></i> Send reset password email</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" @click.prevent="deleteUser()"><i class="fa-solid fa-trash-can"></i> Delete user</a></li>
          </ul>
        </div>
      </div>
    </ModuleHeader>

    <div class="row">
      <div class="col-12 col-lg-5" style="margin-bottom:20px;">
        <Form @submit="onSubmit" ref="form" :initial-values="data" :validation-schema="schema" class="form-horizontal form-bordered">
          <!--<FormField name="username" label="Username" :v-model="data?.username" disabled="true" />-->
          <FormField name="username" label="Email" :v-model="data?.username" disabled="true" />
          <FormField name="firstName" label="First name" :v-model="data?.firstName" />
          <FormField name="lastName" label="Last name" :v-model="data?.lastName" />
          <FormField name="email" label="Email (notifications)" :v-model="data?.email" />
          <FormField name="customerId" label="Customer" as="select" :data="this.$app.$data.customers" addClearOption />
          <FormField name="roleId" v-model="roleId" label="User role" as="select" :data="this.roleList" />

          <FormFooter class="text-end">
            <button class="btn btn-secondary">Save changes</button>   
          </FormFooter>   
        </Form>
      </div>
      <div class="col-lg-7 d-none d-lg-block">
        <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" class="table-no-more" />
      </div>
    </div>

  </div>
</template>

<script>
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FormField from '@/App/components/Form/FormField';
import FormFooter from '@/App/components/Form/FormFooter';
import BaseGrid from '@/App/components/Grid/BaseGrid'
import { Form } from "vee-validate";
import _app from '@/App/App'
//import ChangePasswordModal from './ChangePasswordModal'

export default {
  name: 'UserDetails',
  components: {
    ModuleHeader,
    Form,
    FormField, 
    FormFooter,
    BaseGrid
  },  
  data() {
    return {
      gridData: {
        columns: [
          {
            title: 'Timestamp',
            dataIndex: 'inserted',
            type: 'DateTime'
          },
          {
            title: 'Action',
            dataIndex: 'actionId',
            renderer: (value) => {
              return this.actionDic[value] || value;
            }
          },
          {
            title: 'Entity',
            dataIndex: 'entityTypeId',
            renderer: (value) => {
              return this.entityTypeDic[value] || value;
            }
          },
          {
            title: 'Name',
            dataIndex: 'entityName'
          },
          /*{
            title: 'Details',
            dataIndex: 'details'
          },*/
        ],
        rows: []
      },
      actionList: [],
      actionDic: {},
      entityTypeList: [],
      entityTypeDic: {},
      data: null,
      completeName: null,
      schema: {
        username: 'required|email',
        roleId: 'required',
        email: 'email',
      },
      roleList: [],
      roleId: null,
      customerId: null
    }
  },
  mounted() {
    if (this.$route.params.data) {
      this.data = JSON.parse(this.$route.params.data);
      this.completeName = (this.data.firstName==null || this.data.lastName==null) ? this.data.username : this.data.firstName + ' ' + this.data.lastName;
      this.load(false);
    } else 
      this.$router.back();
  },
  methods: {
    load(reload) {
      //const progress = reload ? this.$progress.start() : null;

      this.gridData.rows = [];

      let loader = this.$loading.show({ container: this.$refs.form.$el }),
          _this = this,
          _auditParams = {
              "rowNum": 1,
              "pageSize": 50,
              "dateFrom": null,
              "dateTo": null,
              "actionId": null,
              "entityTypeId": null,
              "entityName": null,
              "userId": _this.data.id,
              "details": null,
              "entityId": null
          };

      Promise.all([
        _app.$api.get('AppRole/GetAppRoleList', { }),
        _this.$app.$api.post('Audit/GetAuditLogList', _auditParams),
        _this.$app.$data.getStore('AUDIT_LOG_ACTION', 'Audit/GetActionList'),
        _this.$app.$data.getStore('AUDIT_LOG_ENTITY_TYPE', 'Audit/GetEntityTypeList'),
        _this.$app.$data.getCustomers()
      ]).then( (results) => {
        _this.roleList = results[0].data;
        
        this.actionList = results[2];
        this.actionDic = Object.assign({}, ...this.actionList.map((i) => ({[i.id]: i.name})));

        this.entityTypeList = results[3];
        this.entityTypeDic = Object.assign({}, ...this.entityTypeList.map((i) => ({[i.id]: i.name})));

        let _log = results[1].data,
            _userId = _this.data.id;

        if(_log.length>0) {
          let _rows = (_log.length<20) ? _log.length : 20;
          for(let l=0; l<_rows; l++) {
            if(_log[l].userId == _userId)
              _this.gridData.rows.push(_log[l]);    
          }
        }

        this.customers = results[4].data;

        if(reload) {
          this.$app.$api.get('AppUser/GetAppUser', { params: {id:_this.data.id} }).then(
            result => {
              //if (progress) progress.finish();
              _this.data = result.data;
              _this.roleId = _this.data.roleId;
              _this.customerId = _this.data.customerId;
              _this.completeName = (_this.data.firstName==null || _this.data.lastName==null) ? _this.data.username : _this.data.firstName + ' ' + _this.data.lastName;
              loader.hide();
            },
            error => {
              //if (progress) progress.finish();
              loader.hide();
              this.$app.handleError(error);          
            }
          );
        } else {
          _this.roleId = _this.data.roleId;
          _this.customerId = _this.data.customerId;
          loader.hide();
        }
      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
    },
    onSubmit(values) {

      let loader = this.$loading.show({ container: this.$refs.form.$el });

      this.data.firstName = values.firstName;
      this.data.lastName = values.lastName;
      this.data.email = values.email;
      this.data.customerId = values.customerId;
      this.data.roleId = values.roleId;

      this.$app.$api.post('AppUser/SetAppUser', this.data).then(result => {
        if( result.data && parseInt(result.data)>0 ) {
          loader.hide();
          this.load(true);
        } else {
          _app.notify('There was no error but User Id was not returned. Please, try again.');
          loader.hide();
        }
      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
    },
    /*changePassword() {
      this.$vfm.show({
        component: ChangePasswordModal,
        bind: {
          'editRec': this.data
        },
        on: {
          ok: (data) => {
            this.load(true);
          }
        }
      });
    },*/
    sendActivationEmail() {
      _app.$modal.confirm('Send activation email', `Are you sure you want to (re)send activation email to "${this.completeName}" user?`, (confirmed) => {

        if (!confirmed) 
            return;
      
        let loader = this.$loading.show({ container: this.$refs.form.$el });

        this.$app.$api.get('AppUser/SendRegistrationEmail', { params: {id:this.data.id} } ).then(
          (response) => {
            loader.hide();
            _app.notify('Registration email sent to ' + response.data);
          },
          (error) => {            
            loader.hide();
            _app.handleError(error);
          }
        );
      });
    },
    sendResetPasswordEmail() {

      _app.$modal.confirm('Send reset password email', `Are you sure you want to send reset password email to "${this.completeName}" user?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        let loader = this.$loading.show({ container: this.$refs.form.$el });

        this.$app.$api.get('AppUser/SendResetPasswordEmail', { params: {id:this.data.id} } ).then(
          (response) => {
            loader.hide();
            _app.notify('Reset password email sent to ' + response.data);
          },
          (error) => {            
            loader.hide();
            _app.handleError(error);
          }
        );
      });
    },
    deleteUser() {
      _app.$modal.confirm('Delete user', `Are you sure you want to delete "${this.completeName}" user?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        let loader = this.$loading.show({ container: this.$refs.form.$el }),
            _this = this;
        
        this.$app.$api.get('AppUser/DelAppUser', { params: {id:this.data.id} } ).then(
          () => {
            loader.hide();
            if(_this.data.id!==_this.$store.state.auth.user.id)
              _this.$router.back();
            else {
              _this.$store.dispatch('auth/logout').then(
                () => {
                  _this.$router.push('/login');
                },
                  (error) => {}
              );
            }
          },
          (error) => {
            _app.notify('Error deleting user ' + this.completeName + '! Please, try again.');
            loader.hide();
            _app.handleError(error);
          }
        );  
      });
    }
  } 
}
</script>

<style scoped>

</style>