<template>

  <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">

    <div v-if="fullWidth > ( (!this.$app.user.isCustomerUser()) ? mediumScreenWidth : smallScreenWidth )">
      <ModuleHeader title="Analytics" :subtitle="this.$store.state.Customer.name" iconClass="inf-module-header-analytics inf-module-header-img">
        <div class="d-flex">
          <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true" :loading="loading">
            <!--<BaseField v-if="!this.$app.user.isCustomerUser()" @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="this.$app.$data.customers" :disabled="loading" style="width:250px; margin-right: 20px; margin-top: -3px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />-->
            <BaseField @change="changeReport" v-model="state.reportId" name="reportId" placeholder="Report" as="select" required :data="reports" :disabled="loading || !state.customerId" style="width:250px; margin-right: 20px; margin-top: -3px;" class="custom-select" />
            <i v-show="state.subView=='overview'" @click="printAllIframes" class="fa fa-print" style="font-size:large; margin-left:0px; margin-top:7px; color:#2e3d53; cursor:pointer;"></i>
          </FilterBar>
        </div>
      </ModuleHeader>
    </div>
    <div v-else>
      <ModuleHeader title="Analytics" :subtitle="this.$store.state.Customer.name" iconClass="inf-module-header-analytics inf-module-header-img" />

      <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true" :loading="loading" style="margin-top:-10px;">
        <!--<BaseField v-if="!this.$app.user.isCustomerUser()" @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="this.$app.$data.customers" :disabled="loading" style="width:250px; margin-right: 20px; margin-top: -3px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />-->
        <BaseField @change="changeReport" v-model="state.reportId" name="reportId" placeholder="Report" as="select" required :data="reports" :disabled="loading || !state.customerId" style="width:250px; margin-right: 20px; margin-top: -3px;" class="custom-select" />
        <i v-show="state.subView=='overview'" @click="printAllIframes" class="fa fa-print" style="font-size:large; margin-left:0px; margin-top:7px; color:#2e3d53; cursor:pointer;"></i>
      </FilterBar>
    </div>

    <div v-show="!isDashboardLoaded && birdComment" class="text-center" style="margin-top:96px;">
      <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
      <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;" v-html="birdComment"></p>
    </div>

    <div v-show="isDashboardLoaded && state.subView=='overview'" class="mt-4 mb-4 text-center">
      <div v-for="frame in this.iframes" :key="frame.name" class="row" style="margin-top:40px;">
        <div class="col-1" style="cursor:pointer;" :title="'Click to get detailed report from '+capitalizeFirstLetter(frame.class.substring(3))">
          <i @click="openDetailedReport(frame.linkId)" :class="'fa-brands '+frame.class" style="font-size:xx-large;"></i>
        </div>
        <div class="col-11 " style="position: relative; max-width:1240px;">
          <!--<i class="top-left-icon fa fa-refresh" @click="refreshIframe(frame.name)" :title="'Click to refresh this overview report from '+capitalizeFirstLetter(frame.class.substring(3))"></i>-->
          <iframe :id="frame.name" :name="frame.name" class="overview-iframe" scrolling="no" width="100%" :height="frame.height" :src="frame.src" frameborder="0" style="border:0;" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads" :style="'max-height:'+frame.height+'px;'"></iframe>
        </div>
      </div>
    </div>

    <!--
    <div v-show="isDashboardLoaded && state.subView=='kpis'" class="mt-4 mb-4">
      <KpisView :customerId="this.$store.state.Customer.id" :customerConfig="this.$store.state.Customer.config"  />
    </div>
    -->

    <div v-show="isDashboardLoaded && state.subView=='details'" v-html="iframeHtml" class="mt-4 text-center"></div>

  </div>

</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'
//import KpisView from './KpisView'
import { ref } from 'vue';

const tsfLogo = require('../../assets/img/Logo-big.png');

export default {
  name: 'DashboardView',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    //KpisView,
  },
  data: function() {
    return {
      //data vars
      customerConfig: [],
      reports: [],
      iframes: [],
      state: _app.$data.getState('DashboardFilter', { 
        customerId: null,
        reportId: null,
        subView: null,
      }),
      user: this.$store.state.auth.user,

      //gui vars
      birdComment: null,
      birdCommentDashboard: `Select a client and I'll fetch data analytics for you.`,
      defaultSubView: 'overview', //or 'details'
      defaultInitSubView: 'overview', //or 'details'
      iframeHtml: null,
      isDashboardLoaded: false,
      fullHeight: 0,
      fullWidth: 0,
      loading: false,
      mediumScreenWidth: 991,
      mobileListWidth: 250,
      smallScreenWidth: 767,
    }
  },
  mounted() {
    window.addEventListener('resize', this.doLayout);

    window.addEventListener('message', this.handleDashboardMsg);

    //setTimeout(() => {
      //setTimeout(() => {
        this.fullHeight = ref(window.innerHeight);
        this.fullWidth = ref(window.innerWidth); //this.$refs.bodyEl.clientWidth;

        //this.restyleListBoxes();

        if(!this.state.subView)
          this.state.subView = this.defaultSubView;

        if(this.user.customerId)
          this.state.customerId = this.user.customerId;
        else
          this.state.customerId = this.$store.state.Customer.id;

        if(this.state.customerId) {
          this.setCustomerConfig(this.state.customerId);

          //this.reports = this.customerConfig.powermyanalitics.reports;
          this.setReports();

          if(this.state.subView=='details') {
            this.loadDashboard(this.state.customerId, null);
          }
          else if(this.state.subView=='overview') {
            this.state.reportId = 'overview';
            this.loadOverview(this.state.customerId);
          }
          /*else if(this.state.subView=='kpis') {
            this.state.reportId = 'kpis';
            this.loadKpis(this.state.customerId);
          }*/
        }
        else
          this.birdComment = this.birdCommentDashboard;

      //}, 200);
    //}, 200);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.doLayout);
    window.removeEventListener('message', this.handleDashboardMsg);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.state.customerId = this.$store.state.Customer.id;
      this.changeCustomer();
    }
  },
  methods: {
    setCustomerConfig(customerId) {
      this.customerConfig = JSON.parse(
                              this.$app.$data.customers.filter(obj => {
                                return obj.id==customerId;
                              })[0].config
                            ) || [];
      
      /*if (this.customerConfig.scraping !== undefined) {
        this.customerConfig.scraping.forEach(site => {
          site.collapsed = false;
        });

        this.isReportLoaded = true;
      }*/
    },
    restyleListBoxes() {
      /*const elements = document.querySelectorAll('.custom-select');
      elements.forEach(el => {
        el.style.setProperty('color', '#2E3D53', 'important');
        el.style.setProperty('font-size', '14px', 'important');
        el.style.setProperty('font-weight', 'bolder', 'important');
        el.style.setProperty('cursor', 'pointer', 'important');
      });*/

      if(this.fullWidth<=this.mediumScreenWidth)
        this.calcCustomerListWidth(this.fullWidth);
    },
    calcCustomerListWidth(fullWidth) {
      let width = (fullWidth-40)/2;
      if(width>250)
        width = 250;
      this.mobileListWidth = width;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    refreshIframe(iframeId) {
      var iframe = document.getElementById(iframeId);
      iframe.src = iframe.src+'';
    },
    handleDashboardMsg(event) {
      /*if (event.origin !== "https://lookerstudio.google.com")
        return;*/
      
      const iframeElement = document.getElementById("lookerDashboard");
      if(iframeElement) {
        if (event.source === iframeElement.contentWindow) {
          this.isDashboardLoaded = true;
          console.log("Received message:", event.data);
          /*console.log(JSON.parse(event.data));
          switch (event.type) {
            case "dashboard:run:start":
              console.log("The embedded dashboard has started loading"); 
          }*/
        }
      }
    },
    changeCustomer() {
      this.reports = [];
      this.iframes = [];
      this.state.reportId = null;
      this.isDashboardLoaded = false;
      this.defaultSubView = this.defaultInitSubView;

      let id = this.state.customerId;
      
      if(id) {
        this.setCustomerConfig(id);
        
        setTimeout(() => {
          this.setReports();
          this.state.subView = this.defaultSubView;

          if(this.state.subView=='details') {
            this.loadDashboard(this.state.customerId, null);
          }
          else if(this.state.subView=='overview') {
            this.state.reportId = 'overview';
            this.loadOverview(this.state.customerId);
          }
          /*else if(this.state.subView=='kpis') {
            this.state.reportId = 'kpis';
            this.loadKpis(this.state.customerId);
          }*/
        }, 100);
      }
      else
        this.birdComment = this.birdCommentDashboard;
    },
    setReports() {
      let reports = this.customerConfig.powermyanalitics.reports;

      if(this.fullWidth<=991) { //get mobile dashboards
        this.reports = reports.filter(obj => {
                          return obj.isMobile==true;
                        });
      }
      else { //get desktop dashboards
        this.reports = reports.filter(obj => {
                          return obj.isMobile==false;
                        });
      }

      /*if (this.customerConfig.pmakpis !== undefined) { //this should not happen!  
        if(this.customerConfig.pmakpis.kpis !== undefined) {
          if(this.customerConfig.pmakpis.kpis.length>0)
            this.reports.unshift({"name":"Goals & KPIs","id":"kpis","isOverview":false});
          else {
            if(this.defaultSubView=='kpis')
              this.defaultSubView='overview';
          }
        }
        else {
          if(this.defaultSubView=='kpis')
            this.defaultSubView='overview';
        }
      }
      else {
        if(this.defaultSubView=='kpis')
          this.defaultSubView='overview';
      }*/
      
      this.reports.unshift({"name":"Overview","id":"overview","isOverview":true});
    },
    openDetailedReport(id) {
      this.state.reportId = id;
      this.changeReport();
    },
    changeReport() {
      if(this.state.reportId) {
        if(this.state.reportId=='overview') {
          this.state.subView = 'overview';
          this.loadOverview(this.state.customerId);
        }
        else {
          //if(this.state.reportId=='kpis') {
          //  this.state.subView = 'kpis';
          //  this.loadKpis(this.state.customerId);
          //}
          //else {
            this.state.subView = 'details';
            this.loadDashboard(this.state.customerId, this.state.reportId);
          //}
        }
      }
    },
    getCustomerName(customers,id) {
      if(this.state.customerId) {
        return customers.filter(obj => {
                    return obj.id==id;
                })[0].name;
      }
      else
        return null;
    },
    loadDashboard(customerId, reportId) {
      if (this.customerConfig.powermyanalitics !== undefined) {
        let frameHeight = this.fullHeight-170, // ( (this.fullHeight-200)>550 ) ?  (this.fullHeight-250) : (this.fullHeight-200),
            dashboardUrl = this.customerConfig.powermyanalitics.embedUrl,
            platform = '';
        
        if(!reportId) {
            this.state.reportId = this.reports.filter(obj => {
                                    return obj.isDefault==true;
                                  })[0].id;
        }

        dashboardUrl += this.state.reportId;

        this.iframes = [];
        this.iframeHtml = `<iframe id="lookerDashboard" name="lookerDashboard" scrolling="no" width="100%" height="${frameHeight}" src="${dashboardUrl}" frameborder="0" style="border:0;" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"></iframe>`;
        this.isDashboardLoaded = true;
      }
      else {
        this.birdComment = `Ups, I can't find a default dashboard for ${this.getCustomerName(this.$app.$data.customers, customerId)}.`;
        this.iframeHtml = null;
        this.isDashboardLoaded = false;
      }
    },
    loadOverview(customerId) {
      let reports = this.customerConfig.powermyanalitics.reports.filter(obj => {
                        return obj.isOverview==true;
                      }),
          dashboardUrl = this.customerConfig.powermyanalitics.embedUrl,
          frames = [];
      
      for(let i=0; i<reports.length; i++) {
        let linkId = (this.fullWidth>this.mediumScreenWidth) ? reports[i].linkId : reports[i].linkIdMobile;
        frames.push({name:reports[i].name, src:dashboardUrl+reports[i].id, class:reports[i].class, linkId:linkId, height:reports[i].height, platform:reports[i].platform});
      }

      this.iframeHtml = null;

      if(frames.length>0) {
        this.isDashboardLoaded = true;
        this.iframes = frames;
        setTimeout(() => {
          if(this.fullWidth<=this.mediumScreenWidth) {
            const iframes = document.querySelectorAll('iframe.overview-iframe');
            iframes.forEach((iframe) => {
              iframe.style.maxHeight = '200px';
            });
          }
        }, 500);
      }
      else {
        this.birdComment = `Ups, I can't find the overview dashboard for <strong>${this.getCustomerName(this.$app.$data.customers, customerId)}</strong>.`;
        this.isDashboardLoaded = false;
      }
    },
    getCurrentDateFormatted() {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const now = new Date();

      const year = now.getFullYear();
      const month = months[now.getMonth()];
      const day = String(now.getDate()).padStart(2, '0');

      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    },
    printAllIframes() {
      const printWindow = window.open("", "_blank");

      if (!printWindow) return;

      printWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>The Social Formula - Analytics</title>
            <style>
              @page { 
                size: landscape;
              }
              @media print {
                .div-iframe iframe {
                  transform: scale(0.8); /* Scale down to fit page width */
                  transform-origin: top left; /* Anchor scaling to top-left corner */
                  width: 125%; /* Compensate for scaling by adjusting width */
                  height: auto; /* Auto height to maintain aspect ratio */
                }
              }
              body { 
                font-family: Arial, sans-serif; 
                margin: 0; 
                padding: 0; 
              }
              .div-iframe { 
                margin-bottom: 30px;
              }
            </style>
          </head>
          <body>
            <div style="float:right;"><img src="${tsfLogo}" title="Logo" style="width:133px; height:58px;" /></div>
            <p class="pb-3" style="font-size:10px;">Printed: ${this.getCurrentDateFormatted()}</p>
      `);

      if(this.state.subView=='overview') {
        let i=0;
        this.iframes.forEach((frame) => {
          let mt = (i==0) ? '70' : '30';
          printWindow.document.write(`
            <div class="div-iframe" style="margin-top:${mt}px">
              <h2>${frame.platform}</h2>
              <iframe src="${frame.src}" width="100%" height="${frame.height}" frameborder="0" style="border:0;" sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin"></iframe>
            </div>
          `);
          i++;
        });
      }
      else {
        let platform = this.reports.filter(obj => {
                        return obj.id==this.state.reportId;
                      })[0].platform,
            fullHeight = ref(window.innerHeight);

        printWindow.document.write(`
          <div class="div-iframe" style="margin-top:70px">
            <h2>${platform}</h2>
            <iframe src="${this.customerConfig.powermyanalitics.embedUrl+this.state.reportId}" width="100%" height="${fullHeight.value}" frameborder="0" style="border:0;" sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin"></iframe>
          </div>
        `);
      }

      printWindow.document.write(`
          </body>
        </html>
      `);
      printWindow.document.close();

      /*printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };*/
    },
    /*loadKpis(customerId) {
      this.isDashboardLoaded = true;
    },*/
    doLayout() {
      let currentWidth = this.fullWidth,
          isOldMobile = (currentWidth<=this.mediumScreenWidth) ? true : false;

      this.fullWidth = ref(window.innerWidth);
      this.fullHeight = ref(window.innerHeight);

      if(this.fullWidth<=this.mediumScreenWidth)
          this.calcCustomerListWidth(this.fullWidth);

      let isNewMobile = (this.fullWidth<=this.mediumScreenWidth) ? true : false;

      if(isNewMobile!==isOldMobile && this.state.customerId) {
        this.setReports();

        if(this.state.subView=='details') {
          this.loadDashboard(this.state.customerId);
        }
        else if(this.state.subView=='overview') {
          this.state.reportId = 'overview';
          this.loadOverview(this.state.customerId);
        }
        /*else if(this.state.subView=='kpis') {
          this.loadKpis(this.state.customerId);
        }*/
      }
    },
  }
};
</script>

<style scoped>
.top-left-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 16px;
    color: #2e3d53;
    cursor: pointer;
    z-index: 10;
}
.fixed-left {
  /*max-width: 572px;*/
}
.fixed-right {
  width: 360px;
  text-align: right;
}
@media (max-width: 767.98px) {
  .fixed-right {
    width: 100%;
  }
}
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
</style>