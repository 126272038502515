<template>

  <base-modal :name="modalName" title="Flamingo knowledge base" v-bind="$attrs" :width="(fullWidth>=470) ? 450 : (fullWidth-20)" @close="close">
    <template #body>
      <div :style="'max-height:'+(fullHeight-200)+'px; overflow-y:scroll;'">
        <p>Hi, there. Flamingo (yeah, that's me!) is a very smart bird and a helpful assistant. Beside its knowledge that comes from LLM it can analyze data from the files listed below.</p>
        <p>You can ask Flamingo to analyze data, find trends and patterns and provide overall information from the following sources:</p>
        <BaseGrid :columns="gridData.columns" :rows="gridData.rows" @cell-click="onGridCellClick" :enableVScroll="true" class="table-no-more cursor-pointer" />
      </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
    </template>
  </base-modal>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
import BaseGrid from '@/App/components/Grid/BaseGrid'
import _app from '@/App/App'
import { ref } from 'vue';

export default {
  name: 'KBaseModal',
  props: {
    customerId: Number,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    BaseGrid,
  }, 
  data() {
    return {
      files: [],
      fullHeight: 0,
      fullWidth: 0,
      modalName: 'KBaseModal',

      gridData: {
        columns: [
          /*{
            title: 'File id',
            dataIndex: 'id'
          },*/
          {
            title: 'Name',
            dataIndex: 'name',
            renderer: (value) => {
              return value.substring(0, value.indexOf('.'))
            },
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            renderer: (value) => {
              return _app.$helper.formatDate(value, null, 'UTC');
            },
            align: 'center'
          },
        ],
        rows: []
      },
    };
  },
  mounted() {
    this.fullHeight = ref(window.innerHeight);
    this.fullWidth = ref(window.innerWidth);

    if(_app.$helper.isEmpty(this.customerId))
      _app.$helper.notifyInfo('Client is not provided. Please, close this window and try again.');
    else
      this.getFileList(this.customerId);
  },
  methods: {
    close() {
      this.$emit('ok');
      this.$vfm.hide(this.modalName);
    },
    onGridCellClick(row, col, event) {
      return false;
    },
    getFileList(customerId) {
      this.files = [];
      this.gridData.rows = [];
      this.pmaInstructions = '';
    
      let _this = this;

      const progress = _this.$progress.start();
      let loader = _this.$loading.show();

      this.$app.$api.get('Assistant/GetFileList?purpose=Assistants&customerId=' + customerId).then(
        result => {
          try {
            progress.finish();

            let files = JSON.parse(JSON.stringify(result.data));
            for(let i=0; i<files.length; i++) {
              if(files[i].fileName.indexOf('pma.csv')>0)
                _this.files.push({id:files[i].id,name:files[i].fileName, createdAt:files[i].createdAt});
            }

            this.gridData.rows = this.files;
            
            loader.hide();
          }
          catch(err) {
            progress.finish();
            loader.hide();
            _this.$app.handleError(err);
          }
        },
        error => {
          progress.finish();
          loader.hide();
          _this.$app.handleError(error);
        }
      );
    },
  }
}
</script>

<style scoped>
</style>