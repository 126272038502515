<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">
      
      <!--
      <div v-if="fullWidth > smallScreenWidth">
        <ModuleHeader title="Settings" iconClass="inf-module-header-settings inf-module-header-img">
          <div class="d-flex">
            <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true">
              <BaseField @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="customers" :disabled="loading" style="width:250px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />
            </FilterBar>
          </div>
        </ModuleHeader>
      </div>
      <div v-else>
        <ModuleHeader title="Settings" iconClass="inf-module-header-settings inf-module-header-img" />
        
        <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true" style="margin-top:-10px;">
          <BaseField @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="customers" :disabled="loading" style="width:250px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />
        </FilterBar>
      </div>
      -->
      <ModuleHeader title="Settings" :subtitle="this.$store.state.Customer.name" iconClass="inf-module-header-settings inf-module-header-img" />

      <div v-if="!state.customerId" class="text-center" style="margin-top:96px;">
        <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
        <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;" v-html="birdComment"></p>
      </div>

      <div v-if="this.state.customerId!==0">
        <!-- account settings -->
        <div v-show="isAccountSettings && state.customerId" class="row">
          <div class="col-lg-12">
            <section class="card bz-card">
              <header class="card-header">
                <div class="card-actions">
                  <a href="javascript:void(0);" @click.stop="toggleCard('account')" class="card-action card-action-toggle" data-card-toggle=""></a>
                </div>
                <h2 class="card-title">Account</h2>
                <p class="card-subtitle">
                  Change <code>account info</code> and other personal settings below.
                </p>
              </header>
              <div v-show="state.account" class="card-body card-body-bz">
                <div class="row">
                  <div class="col-sm-4 col-md-2">
                    Owner:
                  </div>
                  <div class="col-sm-8 col-md-10">
                    <strong>{{ user.username }}</strong> ({{ user.email }})
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-4 col-md-2">
                    Subscription: 
                  </div>
                  <div class="col-sm-8 col-md-10">
                    <strong>{{ subscription }}</strong>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-4 col-md-2">
                    &nbsp; 
                  </div>
                  <div class="col-sm-8 col-md-10">
                    <button @click="showUsage(state.customerId)" :disabled="!state.customerId" class="btn btn-outline-secondary">Usage</button>
                    <button @click="upgradeMe(state.customerId)" :disabled="!state.customerId" class="btn btn-secondary ms-2">Upgrade</button>
                  </div>
                </div>
              </div>
              <!--
              <footer v-show="state.account" class="card-footer text-end footer-bz">
                <button @click="saveSettings('account')" :disabled="true" class="btn btn-secondary">Save</button>
              </footer>
              -->
            </section>
          </div>
        </div>

        <!-- assistant settings -->
        <!--
        <div v-show="isAssistantSettings && state.customerId" class="row">
          <div class="col-lg-12">
            <section class="card bz-card">
              <header class="card-header">
                <div class="card-actions">
                  <a href="javascript:void(0);" @click.stop="toggleCard('assistant')" class="card-action card-action-toggle" data-card-toggle=""></a>
                </div>
                <h2 class="card-title">Assistant</h2>
                <p class="card-subtitle">
                  Input custom <code>instructions for Flamingo</code> assistant. 
                  It will push it to be of even more help to you.
                </p>
              </header>
              <div v-show="state.assistant" class="card-body card-body-bz">
                <div class="row">
                  <div class="col-lg-12">
                    <textarea class="form-control" v-model="userInstructions" rows="5" placeholder="Type instructions"></textarea>
                  </div>
                </div>
              </div>
              <footer v-show="state.assistant" class="card-footer text-end footer-bz">
                <button @click="saveSettings('assistant')" :disabled="!state.customerId" class="btn btn-secondary">Save</button>
              </footer>
            </section>
          </div>
        </div>
        -->

        <!-- dashboard settings -->
        <div v-show="isDashboardSettings && state.customerId" class="row">
          <div class="col-lg-12">
            <section class="card bz-card">
              <header class="card-header">
                <div class="card-actions">
                  <a href="javascript:void(0);" @click.stop="toggleCard('dashboard')" class="card-action card-action-toggle" data-card-toggle=""></a>
                </div>
                <h2 class="card-title">Goals & KPIs</h2>
              </header>
              <div v-show="state.dashboard" class="card-body card-body-bz">
                <div class="row">
                  <div class="col-10">
                    <p class="card-subtitle">
                      Setup <code>goals/kpis</code> to track your marketing and business progress every day. 
                      You will receive information in our <code>Notifications section</code> and you can also 
                      track a special <code>Goals & KPIs</code> dashboard.
                    </p>
                  </div>
                  <div class="col-2">
                    <button @click="goToGoalsSettings(state.customerId)" :disabled="!state.customerId" class="btn btn-secondary" style="float:right;">Setup</button>
                  </div>
                </div>
                <!--
                <div class="row">
                  <div class="col-sm-6 col-md-8">
                    <label for="url2">Site 1:</label>
                    <BaseField v-model="url1" name="url1" placeholder="Paste website address" />
                  </div>
                  <div class="col-sm-6 col-md-4 mt-2" style="align-self: flex-end;">
                    <BaseCheckbox :checked="url1sentiment" label="sentiment analysis?" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6 col-md-8">
                    <label for="url2">Site 2:</label>
                    <BaseField v-model="url2" name="url2" placeholder="Paste website address" />
                  </div>
                  <div class="col-sm-6 col-md-4 mt-2" style="align-self: flex-end;">
                    <BaseCheckbox :checked="url2sentiment" label="sentiment analysis?" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6 col-md-8">
                    <label for="url2">Site 3:</label>
                    <BaseField v-model="url3" name="url3" placeholder="Paste website address" />
                  </div>
                  <div class="col-sm-6 col-md-4 mt-2" style="align-self: flex-end;">
                    <BaseCheckbox :checked="url3sentiment" label="sentiment analysis?" />
                  </div>
                </div>
                -->
              </div>
              <!--
              <footer v-show="state.dashboard" class="card-footer text-end footer-bz">
                <button @click="saveSettings('dashboard')" :disabled="!state.customerId" class="btn btn-secondary">Save</button>
              </footer>
              -->
            </section>
          </div>
        </div>

        <!-- competitors -->
        <!--
        <div v-show="isCompetitorsSettings && state.customerId" class="row">
          <div class="col-lg-12">
            <section class="card bz-card">
              <header class="card-header">
                <div class="card-actions">
                  <a href="javascript:void(0);" @click.stop="toggleCard('competitors')" class="card-action card-action-toggle" data-card-toggle=""></a>
                </div>
                <h2 class="card-title">Competitors & Research</h2>
              </header>
              <div v-show="state.competitors" class="card-body card-body-bz">
                <div class="row">
                  <div class="col-10">
                    <p class="card-subtitle">
                      Track your <code>competition</code> by defining their home web adresses and other important web addresses 
                      related to your business. Our <code>Flamingo web scrapper</code> will fetch information for you daily so won't 
                      miss anything important.
                    </p>
                  </div>
                  <div class="col-2">
                    <button @click="goToCompetitorsSettings(state.customerId)" :disabled="!state.customerId" class="btn btn-secondary" style="float:right;">Setup</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        -->

        <!-- marketing calendar settings -->
        <div v-show="isCalendarSettings && state.customerId" class="row">
          <div class="col-lg-12">
            <section class="card bz-card">
              <header class="card-header">
                <div class="card-actions">
                  <a href="javascript:void(0);" @click.stop="toggleCard('calendar')" class="card-action card-action-toggle" data-card-toggle=""></a>
                </div>
                <h2 class="card-title">Marketing calendar</h2>
              </header>
              <div v-show="state.calendar" class="card-body card-body-bz">
                <div class="row">
                  <div class="col-10">
                    <p class="card-subtitle">
                      Save important dates so that <code>Flamingo</code> can notify you about upcoming events. 
                      The info will be automatically displayed in our <code>Notifications section</code>.
                    </p>
                  </div>
                  <div class="col-2">
                    <button @click="goToCalendarSettings(state.customerId)" :disabled="!state.customerId" class="btn btn-secondary" style="float:right;">Setup</button>
                  </div>
                </div>
                <!--
                <ModuleHeader title=" ">
                  <button @click="addDate" class="btn btn-sm btn-outline-secondary">Add date</button>
                </ModuleHeader>
                <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" class="table-no-more" :style="(gridData.rows.length>0) ? 'min-height:300px' : ''" />
                -->
              </div>
              <!--
              <footer v-show="state.calendar" class="card-footer text-end footer-bz mt-0 pt-0">
                <button @click="saveSettings('calendar')" :disabled="!state.customerId" class="btn btn-secondary">Save</button>
              </footer>
              -->
            </section>
          </div>
        </div>

      </div>
      <div v-else>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            Enter information to onboard new customer. 
            Changes are not possible via this form. 
            If necessary, changes will be applied by system administrators upon demand.
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12 col-md-6">
            <label for="newCusName">Name:</label>
            <BaseField v-model="newCusName" name="newCusName" placeholder="Customer name" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12 col-md-6">
            <label for="newCusEmail">Email:</label>
            <BaseField v-model="newCusEmail" name="newCusEmail" placeholder="Customer email" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12 col-md-6">
            <label for="newCusAdmin">Admin username:</label>
            <BaseField v-model="newCusAdmin" name="newCusAdmin" placeholder="Customer admin username" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12 col-md-6">
            <label for="newCusOpenAiAssistant">Assistant Id:</label>
            <BaseField v-model="newCusOpenAiAssistant" name="newCusOpenAiAssistant" placeholder="OpenAI assistant id" />
          </div>
        </div>
        <div class="row mt-3 mb-4">
          <div class="col-sm-12 col-md-6">
            <button @click="addCustomer" class="btn btn-secondary">Add customer</button>
          </div>            
        </div>
      </div>
    </div>
  
</template>
  
<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import BaseField from '@/App/components/Form/BaseField'
//import BaseCheckbox from '@/App/components/Form/BaseCheckbox'
//import FilterBar from '@/App/components/Common/FilterBar'
//import BaseGrid from '@/App/components/Grid/BaseGrid'
//import AddDateModal from './AddDateModal'
import { ref } from 'vue';

export default {
  name: 'SettingsView',
  components: {
    ModuleHeader,
    BaseField,
    //BaseCheckbox,
    //FilterBar,
    //BaseGrid,
  },
  data: function() {
    return {
      //data
      customerConfig: [],
      customers: null,
      users: this.$app.$data._stores['USER_LIST'],
      loading: false,
      state: _app.$data.getState('SettingsState', { 
        account: true,
        dashboard: true,
        assistant: true,
        competitors: true,
        calendar: true,
        customerId: null,
      }),
      subscription: ( ( this.$app.user.isCustomerUser() ) ? 'Individual' : 'Company' ) + ' (valid until 31-Dec-2024)',
      user: this.$store.state.auth.user,

      //gui setup
      birdComment: `Select a client in order to access Settings functionality.`,
      isAccountSettings: true,
      isDashboardSettings: true,
      isAssistantSettings: true,
      isCompetitorsSettings: true,
      isCalendarSettings: true,
      isLabUser: false,
      fullHeight: 0,
      fullWidth: 0,
      mediumScreenWidth: 991,
      mobileListWidth: 250,
      smallScreenWidth: 767,

      //form account

      //form dashboard
      url1: null,
      url2: null,
      url3: null,
      url1sentiment: null,
      url2sentiment: null,
      url3sentiment: null,

      //form assistant
      userInstructions: null,

      //form new customer
      newCusName: null,
      newCusEmail: null,
      newCusAdmin: null,
      newCusOpenAiAssistant: null,

      /*
      //form marketing calendar
      gridData: {
        columns: [
          //{
          //  title: '#',
          //  dataIndex: 'id'
          //},
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Date',
            dataIndex: 'date',
            align: 'center'
          },
          {
            title: 'Category',
            dataIndex: 'category'
          },
          {
            title: 'Country',
            dataIndex: 'country_code'
          },
          // actions
          {
            width:140,
            align:'right',
            actions: [
              {
                text: 'Edit',
                handler: this.editDate
              },          
              {
                text: 'Remove',
                handler: this.removeDate
              }
            ]
          }
        ],
        rows: []
      },
      */
    }
  },
  mounted() {
    window.addEventListener('resize', this.doLayout);

    if (this.user)
      this.isLabUser = ( this.$app.$data.Lab22Users.indexOf( this.getEmailPrefixIfDomainMatches(this.user.email) ) == -1 ) ? false : true;

    this.customers = this.$app.$data.customers;
    this.customers = this.customers.filter(item => item.id !== 0);

    if(this.user.customerId)
      this.state.customerId = this.user.customerId;
    else
      this.state.customerId = this.$store.state.Customer.id;

    if(this.state.customerId)
      this.customerConfig = this.getCustomerConfig(this.state.customerId);

    //setTimeout(() => {
      if(this.isLabUser)
        this.addBlankCustomerOption();

      //setTimeout(() => {
        this.fullHeight = ref(window.innerHeight);
        this.fullWidth = ref(window.innerWidth); //this.$refs.bodyEl.clientWidth;

        //this.restyleListBoxes();
      //}, 200);
    //}, 200);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.doLayout);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.state.customerId = this.$store.state.Customer.id;
      this.changeCustomer();
    }
  },
  methods: {
    addBlankCustomerOption() {
      let addNew = true;
      for(let i=0; i<this.customers.length; i++) {
        if(this.customers[i].id==0)
          addNew = false;
      }
      if(addNew) {
        let accountId = this.getAccountId();
        
        if(accountId>0) {
          const newCustomer = {
            "accountId": accountId,
            "id": 0,
            "name": "Add new client"
          };
          this.customers.push(newCustomer);
        }
      }
    },
    addCustomer() {
      let  _this = this,
          data = {
            "accountId": this.getAccountId().toString(),
            "name": this.newCusName,
            "email": this.newCusEmail,
            "username": this.newCusAdmin,
            "assistantId": this.newCusOpenAiAssistant
          };
          /*
          {
          "accountId": "2",
          "name": "this.newCusName",
          "email": "this.newCusEmail",
          "username": "this.newCusAdmin",
          "assistantId": "this.newCusOpenAiAssistant"
          }
          */

      if( !this.$app.isEmpty(data.accountId) && !this.$app.isEmpty(data.name) && !this.$app.isEmpty(data.email) && !this.$app.isEmpty(data.username) && !this.$app.isEmpty(data.assistantId) ) {
        _app.$api.post('Customer/OnboardCustomer', data).then(result => {
          if( result.data ) {
            _app.$helper.notifyInfo('Onboarded customer ' + _this.newCusName + ' (id=' + result.data + '). This page will automatically reload.' );
    
            setTimeout(() => {
              this.$router.push({
                path: this.$route.path,
                query: this.$route.query
              }).then(() => {
                this.$router.go(0);
              });
            }, 3000);
          }
        }, (error) => {
          _app.handleError(error);
        });
      }
      else
        _app.$helper.notifyError('All fields are mandatory. Please, try again.');
    },
    /*addDate() {
      this.$vfm.show({
        component: AddDateModal,
        on: {
          ok: (data) => {
            this.updateCalendarGrid(data, true);
          }
        }
      });
    },*/
    calcCustomerListWidth(fullWidth) {
      let width = (fullWidth-40)/2;
      if(width>250)
        width = 250;
      this.mobileListWidth = width;
    },
    changeCustomer() {
      if(this.state.customerId && this.state.customerId!==0)
        this.customerConfig = this.getCustomerConfig(this.state.customerId);
      else
        this.customerConfig = [];
    },
    doLayout() {
      let currentWidth = this.fullWidth,
          isOldMobile = (currentWidth<=this.mediumScreenWidth) ? true : false;

      this.fullWidth = ref(window.innerWidth);
      this.fullHeight = ref(window.innerHeight);

      if(this.fullWidth<=this.mediumScreenWidth)
          this.calcCustomerListWidth(this.fullWidth);

      let isNewMobile = (this.fullWidth<=this.mediumScreenWidth) ? true : false;

      if(isNewMobile!==isOldMobile) {
        //console.log('toggle desktop/mobile layout)
      }
    },
    /*editDate(rec) {
      this.$vfm.show({
        component: AddDateModal,
        bind: {
          'editRec': rec
        },        
        on: {
          ok: (data) => {
            this.updateCalendarGrid(data, false);
          }
        }
      });
    },*/
    getAccountId() {
      let accountId = 0;
      for(let i=0; i<this.users.length; i++) {
        if(this.users[i].id==this.user.id)
          accountId = this.users[i].accountId;
      }
      return accountId;
    },
    getCustomerConfig(id) {
      this.customerConfig = JSON.parse(
                              this.customers.filter(obj => {
                                return obj.id==id;
                              })[0].config
                            ) || [];

      try {
        if (this.customerConfig.openai.assistantAnalyticsClientInstructions !== undefined)
          this.userInstructions = this.customerConfig.openai.assistantAnalyticsClientInstructions;

        /*if (this.customerConfig.marketingCalendar !== undefined)
          this.gridData.rows = this.customerConfig.marketingCalendar;*/

        /*
        if (this.customerConfig.scraping !== undefined) {
          let config = this.customerConfig.scraping;

          if (config[0] && config[0].url !== undefined) {
            this.url1 = config[0].url;
            this.url1sentiment = (config[0].url!=='') ? config[0].sentimentAnalysis : false;
          }
          if (config[1] && config[1].url !== undefined) {
            this.url2 = config[1].url;
            this.url2sentiment = (config[1].url!=='') ? config[1].sentimentAnalysis : false;
          }
          if (config[2] && config[2].url !== undefined) {
            this.url3 = config[2].url;
            this.url3sentiment = (config[2].url!=='') ? config[2].sentimentAnalysis : false;
          }
        }
        */
      }
      catch(err) {
        this.userInstructions = null;
        //this.gridData.rows = [];
      }
    },
    getEmailPrefixIfDomainMatches(email) {
      const [prefix, domain] = email.split('@');
      
      if (domain === 'lab22.com')
        return prefix;
      else
        return email;
    },
    goToCalendarSettings(customerId) {
      this.$router.push({ name: 'calendar', state: { customerId } });
    },
    goToGoalsSettings(customerId) {
      this.$router.push({ name: 'goals', state: { customerId } });
    },
    goToCompetitorsSettings(customerId) {
      this.$router.push({ name: 'competitors', state: { customerId } });
    },
    /*removeDate(rec) {
      _app.$modal.confirm('Remove date', `Are you sure you want to remove "${rec.name}" from your calendar?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1); 

        this.saveSettings('calendar');
      });
    },*/
    restyleListBoxes() {
      if(this.fullWidth<=this.mediumScreenWidth)
        this.calcCustomerListWidth(this.fullWidth);
    },
    saveSettings(section) {
      let go = true,
          _this = this;

      if(section=='account') {
        _app.$helper.notifyInfo('Sorry, we are still working on this functionality. Saving account settings will be available soon.');
      }
      /*else if(section=='dashboard') {
        _app.$helper.notifyInfo('Sorry, we are still working on this functionality. Saving dashboard settings will be available soon.');
      }
      else if(section=='calendar') {
        if (this.customerConfig && this.customerConfig.marketingCalendar)
          this.customerConfig.marketingCalendar = this.gridData.rows;
        else {
          go = false;
          _app.$helper.notifyError("'marketingCalendar' property is missing in customerConfig");
        }
      }
      else if(section=='assistant') {
        if (this.customerConfig && this.customerConfig.openai)
          this.customerConfig.openai.assistantAnalyticsClientInstructions = this.userInstructions;
        else {
          go = false;
          _app.$helper.notifyError("'openai' property is missing in customerConfig");
        }
      }*/

      if(go)
        pushToDb(this.state.customerId, this.customerConfig, _this);

      function pushToDb(customerId, json, scope) {
        _app.$api.post('Customer/SetCustomerConfig?id='+customerId, json).then(result => {
          if( result.data && parseInt(result.data)>0 ) {
            _app.$helper.notifyInfo('Updated ' + section + ' settings for selected client.');
            
            _app.$data.getCustomers(true).then(data => {
              scope.customers = data;
              scope.customerConfig = scope.getCustomerConfig(customerId);

              let cusName = scope.$app.$helper.getCustomerName(customerId,data);
              scope.$store.dispatch('setAppCustomer', {id:customerId, name:cusName, config:JSON.stringify(json) });
            });
          }
        }, (error) => {
          _app.handleError(error);
        });
      }
    },
    showUsage(customerId) {
      //this.$router.push({ name: 'usage', params: { customerId: customerId } });
      this.$router.push({ name: 'usage', state: { customerId } });
    },
    toggleCard(section) {
      if (Object.prototype.hasOwnProperty.call(this.state, section))
        this.state[section] = !this.state[section];
    },
    /*updateCalendarGrid(rec, isNew) {
      if(isNew) {
        rec.id = this.gridData.rows.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;
        this.gridData.rows.unshift({id:rec.id,name:rec.name,date:rec.date,category:rec.category,country_code:rec.country_code,every_year:true});
      }
      else {
        const index = this.gridData.rows.findIndex(item => item.id === rec.id);
        if (index !== -1)
          this.gridData.rows[index] = rec;
      }
    },*/
    upgradeMe(customerId) {
      _app.$helper.notifyInfo('Sorry, we are still working on this functionality. Upgrade option will be available soon.');
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  div.card-actions {
    position: absolute;
  }
}
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
.bz-card {
  border: 1px solid #dadada;
  max-width: 1024px;
}
.card-body-bz {
  background: #ffffff;
}
.footer-bz {
  border-top: 0px;
}
</style>