<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding">
        
      <ModuleHeader title="40+ Data Sources available" style="font-weight:700 !important;" />

      <p class="p-text mt-4">
        Isn't it great when you can analyze all your data in one place? We certainly think so. 
      </p>

      <p class="p-text mt-4 mb-4">
        We are proud to enable our clients to connect to most social and e-commerce platforms out there.<br />
        Check out the most used data sources below. Or use your own data source, just reach out.
      </p>

      <div class="row" style="max-width:1048px">
        <div v-for="pl in platforms" :key="pl.code" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-0 me-1" style="width:160px; height:180px; margin-bottom:10px !important;">
          <div class="card card-featured-quaternary">
            <div class="card-body text-center" style="background-color: #fff1f3; padding-bottom:0px; height:160px;">
              <img :src="pl.img" style="width:60px; height:60px;" :alt="pl.name">
              <br /><br />
              <strong>{{ pl.name }}</strong>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
  
<script>
import ModuleHeader from '@/App/components/Common/ModuleHeader'

export default {
  name: 'SourcesView',
  components: {
    ModuleHeader,
  },
  data: function() {
    return {
      platforms: this.$store.state.platforms,
    }
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.p-text {
  color: #2e3d53;
  font-size: 16px;
}
</style>